body {
  min-width: 1920px;
  max-width: 1920px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  font-family: 'PT Root UI', 'Arial', sans-serif;
  line-height: 22px;
  text-transform: none;
}

a {
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
}

.print-all-card {
  display: none;
}

.table-print {
  display: none;
  margin-top: 1vh;
}

@media print {
  html,
  body {
    min-width: 100%;
    max-width: 100%;
  }

  .print-all-card {
    display: flex;
    flex-direction: column;
  }

  .print-block {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 1.5vh;
    page-break-before: always;
    page-break-after: always;
    &.print-block_blue {
      margin-top: 8vh;
    }

    &.print-block_red {
      margin-top: 0vh;
    }

    &.print-block_green {
      margin-top: 6vh;
    }

    &.print-block-nail_green:first-child {
      margin-top: 1vh;
    }

    &.print-block-nail_green {
      margin-left: 2vh;
    }

    &.print-block_lab {
      margin-top: 3vh;
    }

    &.print-block_packaging {
      margin-top: 16vh;
    }

    &.print-block_nails {
      margin-top: 0;
    }

    &.print-block_sharpening-machine {
      margin-top: 60px;
      margin-bottom: 60px;
    }

    &.print-block_rolling-machine {
      margin-bottom: 110px;
    }

    &.print-block_cto {
      margin-top: 7vh;
    }

    &.print-block_manz {
      margin-bottom: 50vh;
    }

    &.print-block_white {
      margin-top: 12vh;
    }
    &.print-block_white-tag {
      width: 500px;
      margin-top: 10vh;
      margin-left: 10vw;
    }
    &.print-block_red-tag {
      width: 500px;
      margin-top: 10vh;
      margin-left: 10vw;
    }
    &.print-block_red-mid-tag {
      margin-top: 10vh;
      margin-left: 10vw;
    }
    &.print-block_white-medium-tag {
      margin-top: 5vh;
    }
    &.print-block_yellow-medium-tag {
      margin-top: 10px;
    }
    &.print-block_yellow_tag {
      margin-bottom: 122px;
      margin-left: 20px;
      margin-right: 20px;
    }
    &.print-block_blue-tag {
      margin-bottom: 95px;
      margin-left: 25px;
      margin-right: 25px;
    }
    &.print-block_green_tag {
      width: 500px;
      margin-top: 10vh;
      margin-left: 10vw;
    }
  }

  // &.print-ugv-production {
  //   margin-top: 10px;
  //   margin-bottom: 10px;
  // }

  // &.print-block-nail_green:first-child {
  //   margin-top: 1vh;
  // }

  // &.print-block-nail_blue {
  //   width: 600px;
  //   margin-top: 10vh;
  //   margin-left: 10vw;
  // }

  // &.print-block-wire {
  //   width: 600px;
  //   margin-top: 10vh;
  //   margin-left: 10vw;
  // }

  .table-print {
    display: block;
    margin-top: 1vh;
  }
}

img {
  width: 100%;
  height: auto;
}

.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

// custom scroll for chrome

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

::-webkit-scrollbar-thumb {
  background-color: #167ffb;
  border-radius: 100px;
}

// custom scrollbar for firefox

* {
  scrollbar-color: rgba(0, 23, 41, 0.12) white;
  scrollbar-width: thin;
}
