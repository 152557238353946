.barcodeContainer {
  position: absolute;
  top: 180px;
  right: 208px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

:global(.print-block_blue) .barcodeContainer {
  top: 145px;
}

:global(.print-block_yellow) .barcodeContainer {
  top: 150px;
}

:global(.print-block_yellow_tag) .barcodeContainer {
  top: 230px;
  right: 170px;
}

:global(.print-block_blue-tag) .barcodeContainer {
  top: 230px;
  right: 180px;
}

:global(.print-block_green_tag) .barcodeContainer {
  top: 260px;
  right: 250px;
}

:global(.print-block_red) .barcodeContainer {
  top: 120px;
}

:global(.print-block_white) .barcodeContainer {
  top: 192px;
  right: 215px;
}

:global(.print-block_white-tag) .barcodeContainer {
  top: 260px;
  right: 250px;
}

:global(.print-block_red-tag) .barcodeContainer {
  top: 260px;
  right: 250px;
}

:global(.print-block_green-mid-tag) .barcodeContainer {
  top: 200px;
  left: 10px;
}

:global(.print-block_red-mid-tag) .barcodeContainer {
  top: 230px;
  right: 460px;
}

:global(.print-block_red-mid-tag) .qrCodeContainer {
  top: 220px;
  right: 80px;
}

:global(.print-block_white-medium-tag) .barcodeContainer {
  top: 200px;
  right: 450px;
}

:global(.print-block_yellow-medium-tag) .barcodeContainer {
  top: 170px;
  right: 410px;
}

:global(.print-block_blue-medium-tag) .barcodeContainer {
  top: 240px;
  right: 460px;
}

.barcodeContainerTwo {
  position: absolute;
  right: 208px;
  bottom: 8px;
  top: 410px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

:global(.print-block-wire) .barcodeContainerWireOne {
  position: absolute;
  top: 320px;
  left: 25px;
}

:global(.print-block-wire) .barcodeContainerWireTwo {
  position: absolute;
  top: 250px;
  left: 400px;
}

:global(.print-block-wire) .qrContainerWireOne {
  position: absolute;
  top: 300px;
  left: 330px;
}

:global(.print-block-wire) .qrContainerWireTwo {
  position: absolute;
  top: 250px;
  left: 650px;
}

:global(.print-block_yellow) .barcodeContainerTwo {
  bottom: 0;
}

:global(.print-block_white) .barcodeContainerTwo {
  top: 473px;
  right: 220px;
}

:global(.print-block_lab) .barcodeContainer {
  top: 164px;
  right: 80px;
  bottom: -5px;
}

:global(.print-block_red) .barcodeContainerTwo {
  top: 320px;
}

.barcodeContainerThree {
  position: absolute;
  top: 0;
  right: 527px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.barcode {
  width: 100px;
  max-width: 100px;
  max-height: 28px;
  margin-bottom: 4px;
  object-fit: cover;
}

.qrCodeContainer {
  position: absolute;
  top: 15px;
  right: 15px;
  overflow: hidden;
}

:global(.print-block_blue) .qrCodeContainer {
  top: 30px;
}

:global(.print-block_white) .qrCodeContainer {
  top: 200px;
  right: 110px;
}

:global(.print-block_white-tag) .qrCodeContainer {
  top: 260px;
  right: 80px;
}
:global(.print-block_white-medium-tag) .qrCodeContainer {
  top: 180px;
  right: 80px;
}

:global(.print-block_yellow-medium-tag) .qrCodeContainer {
  top: 160px;
  right: 100px;
}

:global(.print-block_blue-medium-tag) .qrCodeContainer {
  top: 220px;
  right: 80px;
}

:global(.print-block_red-tag) .qrCodeContainer {
  top: 300px;
  right: 80px;
}

:global(.print-block_green-mid-tag) .qrCodeContainer {
  top: 180px;
  right: 80px;
}

:global(.print-block_green_tag) .qrCodeContainer {
  top: 220px;
  right: 80px;
}

:global(.print-block_blue-tag) .qrCodeContainer {
  top: 215px;
  right: 60px;
}

:global(.print-block-nail_green) .qrCodeContainer {
  top: 10px;
  right: 120px;
}

:global(.print-block-nail_blue) .qrCodeContainer {
  top: 10px;
  right: 120px;
}

:global(.print-block_yellow) .qrCodeContainer {
  top: 40px;
}

:global(.print-block_yellow_tag) .qrCodeContainer {
  top: 205px;
  right: 50px;
}

:global(.print-block_lab) .qrCodeContainer {
  top: 50px;
}

.qrCodeContainerTwo {
  position: absolute;
  top: 270px;
  right: 15px;
  overflow: hidden;
}

:global(.print-block_blue) .qrCodeContainerTwo {
  top: 240px;
}

:global(.print-block_yellow) .qrCodeContainerTwo {
  top: 250px;
}

:global(.print-block_white) .qrCodeContainerTwo {
  top: 480px;
  right: 120px;
}

:global(.print-block_lab) .qrCodeContainerTwo {
  top: 260px;
}

.qrCode {
  width: 85px;
  max-width: 85px;
  height: 85px;
  object-fit: cover;
  object-position: -15px -15px;
}

.line {
  position: absolute;
  top: 52.5%;
  left: 0;
  width: 100%;
  height: 1px;
  border-top: 1px dashed #000;
}

.verticalLine {
  position: absolute;
  top: 0;
  left: 43.5%;
  width: 1px;
  height: 100%;
  border-right: 1px dashed #000;
}

.weightCard {
  table-layout: fixed;
  border-collapse: collapse;
  width: 360px;
  font-size: 11px;
  font-weight: bold;
  margin-top: 2vh;
  margin-left: 5vw;

  td {
    padding: 0 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  td.center {
    text-align: center;
  }

  td > span {
    vertical-align: middle;
  }

  .value {
    font-size: 1.5em;
    text-align: center;
  }

  .rightValue {
    @extend .value;
    padding-right: 50px;
  }

  .bigValue {
    @extend .value;
    font-size: 2em;
  }

  .smallValue {
    @extend .value;
    font-size: 1.3em;
  }

  .cardBarcode {
    padding: 2px;
  }

  .bigRightValue {
    @extend .rightValue;
    @extend .bigValue;
  }

  .bigTitle {
    font-size: 1.5em;
  }
}

.borderedWeightCard {
  @extend .weightCard;

  tr {
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
  }

  tr.innerRow {
    border-top: none;
  }

  tr.bottomRow {
    border-bottom: 1px solid black;
  }

  .rightValue {
    border-left: 1px solid black;
  }

  .leftValue {
    @extend .value;
    border-right: 1px solid black;
  }

  .bigLeftValue {
    @extend .leftValue;
    @extend .bigValue;
  }

  .smallLeftValue {
    @extend .leftValue;
    @extend .smallValue;
  }
}
