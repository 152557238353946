body {
	background-color: #F7F8F8;
}

@font-face {
	font-family: 'PT Root UI';
	src: url('../fonts/PT%20Root%20UI_Regular.eot');
	src:
	     url('../fonts/PT%20Root%20UI_Regular.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/PT%20Root%20UI_Regular.woff2') format('woff2'),
	     url('../fonts/PT%20Root%20UI_Regular.woff') format('woff'),
	     url('../fonts/PT%20Root%20UI_Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PT Root UI';
	src: url('../fonts/PT%20Root%20UI_Bold.eot');
	src:
	     url('../fonts/PT%20Root%20UI_Bold.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/PT%20Root%20UI_Bold.woff2') format('woff2'),
	     url('../fonts/PT%20Root%20UI_Bold.woff') format('woff'),
	     url('../fonts/PT%20Root%20UI_Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'PT Root UI';
	src:
		url('../fonts/Ptrootuimedium.woff2') format('woff2'),
		url('../fonts/Ptrootuimedium.woff') format('woff'),
		url('../fonts/PT%20Root%20UI_Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
